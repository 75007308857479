/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body{
  margin: 0;
  padding:0;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.02em;
}

.text-end{
  text-align: right;
}

ion-content{
  // --background: #dbdbdb;
  --background: #FCFCFC;
}

.split-pane-visible > .split-pane-side {
  min-width: 280px !important;
  max-width: 280px !important;
}

.tall-modal {
  --height: 700px !important;
}

@media(min-width:992px){
  .medium-modal {
    --height: 90vh;
    --width: 840px;
  }
}

.status-card{
  box-shadow: 0px 0px 10px #0000001c;
  border-radius: 10px;
  min-height: calc(100% - 12px);
  margin: 6px;
  ion-text{
    font-size: 14px;
    position: relative;
    margin-bottom: 10px;
    & + ion-text:last-child{
      margin-bottom: 0px;
    }
  }
  ion-badge{
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    padding: 6px 10px;
  }
  
}

.loading-dots::after {
  content: ".";
  animation: loading 1.5s infinite steps(3);
}

.loading {
  text-align: center;
  color:#334155;
}

.short-modal {
  --height: 450px;
}

.low-modal{
  --height: 200px;
  --width: 320px;
}

.modal-content {
  padding: 20px;
}
.text-common {
  font-size: 13px !important;
  color: #6f7782 !important;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.text-right {
  text-align: right;
}
.text-bold {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
margin: 0;
}

ion-toolbar {
  --background: #072C36;
  // --background: linear-gradient(61deg, rgba(36,151,184,1) 10%, rgba(147,149,152,1) 52%, rgba(17,106,170,1) 100%);
}

.alert-button-group{
  justify-content: center !important;
}


.alert-button{
  background-color: rgb(36,151,184) !important;
  color: #fff !important;
}

.ngx-datatable.material{
  box-shadow: none;
}

.date-field{
  position: relative;
  .displayDate{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 4px;
    padding: 5px 10px;
    background-color: #ffffff;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  .ion-datetime{
    opacity: 0;
  }
}

.form-group{
  text-align: left;
  .displayDate{
    position: relative;
    right: auto;
    left: 0;
    width: auto;
    text-align: left;
  }
  .ion-datetime{
    position: absolute;
    top: 0;
    left: 0;
  }
}

.danger{
  color: red;
}